var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('total',{staticClass:"mb-4",attrs:{"total":_vm.counts}}),_c('v-data-table',{staticClass:"elevation-3 table table__custom",attrs:{"headers":_vm.visibleHeaders,"items":_vm.filteredData,"hide-default-footer":"","items-per-page":_vm.itemsPerPage,"options":_vm.options,"custom-sort":_vm.customSort,"loading":_vm.load,"sort-by":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.finished",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.finished),"dark":""}},[_vm._v(" "+_vm._s(item.finished)+" "),_c('p')])]}},{key:"header.actions",fn:function(){return [_c('p',{staticClass:"search_category",on:{"click":function($event){$event.stopPropagation();_vm.show = !_vm.show}}},[_c('v-icon',{attrs:{"color":"#cbd3e9","small":""}},[_vm._v("search")]),_c('span',[_vm._v(_vm._s(_vm.$t("searchCat")))])],1)]},proxy:true},(_vm.headers)?{key:"body.prepend",fn:function(){return [(_vm.show)?_c('tr',_vm._l((_vm.visibleHeaders),function(item){return _c('td',{key:("search_" + (item.value))},[(item.value !== 'actions')?_c('v-text-field',{staticClass:"table-search",attrs:{"height":"30","append-icon":"mdi-magnify","hide-details":"","background-color":"#fff","rounded":"","single-line":""},on:{"input":function($event){return _vm.search($event, item.value)}}}):_c('v-icon',{attrs:{"small":""},on:{"click":function($event){_vm.show = false}}},[_vm._v("close")])],1)}),0):_vm._e()]},proxy:true}:null,{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","x-small":"","tag":"router-link","to":("/taskEdit/" + (item.id)),"icon":"","center":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-menu',{attrs:{"offset-x":"","left":"","nudge-top":"50%","nudge-left":"10px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.verificationRole())?_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")]):_vm._e()]}}],null,true)},[_c('deliteItem',{on:{"close":function($event){_vm.dialogDelete = false},"confirm":_vm.deleteItemConfirm}})],1)]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"nodata"},[_vm._v(_vm._s(_vm.$t("title.nodata")))])]},proxy:true}],null,true)}),(_vm.pageCounter > 1)?_c('div',{staticClass:"pagination-block"},[_c('v-pagination',{attrs:{"length":_vm.pageCounter,"total-visible":7,"circle":""},model:{value:(_vm._page),callback:function ($$v) {_vm._page=$$v},expression:"_page"}})],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('edit-item',{attrs:{"headers":_vm.toEditHeaders,"record":_vm.editedItem,"title":_vm.modalTitle,"orderEdit":_vm.orderEdit},on:{"close":_vm.close,"new":_vm.createRecord,"edit":_vm.editRecord}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }