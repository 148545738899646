var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-row',[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"transparent","small":"","elevation":"0"},on:{"click":_vm.backOrder}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t("buttons.back"))+" ")],1),_c('v-col',{staticClass:"d-flex justify-space-between align-center",attrs:{"cols":"12"}},[_c('h2',{staticClass:"main-title"},[_vm._v(_vm._s(_vm.$t("new.order"))+" № "+_vm._s(_vm.orderId))]),(_vm.task)?_c('div',{staticClass:"task-button"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"primary","dark":""},on:{"click":_vm.onUpdate}},[_c('v-icon',[_vm._v("loop")])],1),_c('v-btn',{attrs:{"outlined":"","color":"primary","dark":""},on:{"click":_vm.onAdd}},[_c('v-icon',[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t("buttons.add"))+" ")],1)],1):_vm._e()])],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"lg":"4","md":"6","cols":"12"}},[_c('v-btn',{staticClass:"btn__task",class:{ active: _vm.activeButton === 'order' },attrs:{"width":"100%"},on:{"click":_vm.buttonOrder}},[_vm._v(_vm._s(_vm.$t("buttons.orderCard")))])],1),_c('v-col',{attrs:{"lg":"4","md":"6","cols":"12"}},[_c('v-btn',{staticClass:"btn__task",class:{ active: _vm.activeButton === 'task' },attrs:{"width":"100%"},on:{"click":function($event){_vm.getFilterData(_vm.objectProfile, _vm.objectVehicle, _vm.sortOptions, _vm.page),
            _vm.buttonTask()}}},[_vm._v(_vm._s(_vm.$t("buttons.taskTransportation")))])],1)],1),(_vm.orderCard)?_c('v-row',[_c('v-col',{attrs:{"lg":"8","md":"12"}},[_c('div',{staticClass:"order-col"},_vm._l((_vm.ORDERFIELDS),function(header){return _c('different-fields',{key:header.value,staticClass:"order-field",attrs:{"headerObj":header,"orderEdit":null},model:{value:(_vm.ORDER[header.value]),callback:function ($$v) {_vm.$set(_vm.ORDER, header.value, $$v)},expression:"ORDER[header.value]"}})}),1),_c('v-textarea',{attrs:{"placeholder":_vm.labelTextarea,"value":_vm.ORDER.notation,"background-color":"#F1F3F9","color":"#A6ACC4","rows":"3","no-resize":"","filled":""},model:{value:(_vm.ORDER.notation),callback:function ($$v) {_vm.$set(_vm.ORDER, "notation", $$v)},expression:"ORDER.notation"}}),_c('v-btn',{attrs:{"color":"primary mt-4","block":"","height":"38","width":"450","loading":_vm.loadBtn},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.save"))+" ")])],1)],1):_vm._e(),(_vm.task)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table-edit',{attrs:{"headers":_vm.headers,"users_data":_vm.TASKSORDER,"toEditHeaders":_vm.TASKFIELDS,"counts":_vm.COUNT,"toEditItem":_vm.TASK,"orderEdit":_vm.orderId,"modalTitle":_vm.$t('new.editingTransportationTask'),"page":_vm.page,"load":_vm.load},on:{"getItemForEdit":_vm.onEditItemFromTable,"editRecord":_vm.editRecord,"deleteRecord":_vm.deleteRecord,"onPagination":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm.getFilterData.apply(
            void 0, [ _vm.objectProfile,
            _vm.objectVehicle,
            _vm.sortOptions ].concat( argsArray )
          )},"sortBy":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.getFilterData.apply(void 0, [ _vm.objectProfile, _vm.objectVehicle ].concat( argsArray, [_vm.page] ))}}})],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('edit-item',{attrs:{"headers":_vm.toEditHeaders,"record":_vm.newRecord,"title":_vm.$t('new.addingTransportationTask'),"orderEdit":_vm.orderId,"load":_vm.load},on:{"close":_vm.close,"edit":_vm.createRecord}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }