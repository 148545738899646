<template>
  <div class="home">
    <v-row>
      <v-btn
        color="transparent"
        small
        @click="backOrder"
        elevation="0"
        class="mb-2"
      >
        <v-icon small left>mdi-arrow-left</v-icon>
        {{ $t("buttons.back") }}
      </v-btn>
      <v-col cols="12" class="d-flex justify-space-between align-center">
        <h2 class="main-title">{{ $t("new.order") }} № {{ orderId }}</h2>
        <div class="task-button" v-if="task">
          <v-btn outlined color="primary" dark @click="onUpdate" class="mr-2">
            <v-icon>loop</v-icon>
          </v-btn>
          <v-btn outlined color="primary" @click="onAdd" dark>
            <v-icon>add</v-icon>
            {{ $t("buttons.add") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col lg="4" md="6" cols="12">
        <v-btn
          :class="{ active: activeButton === 'order' }"
          class="btn__task"
          width="100%"
          @click="buttonOrder"
          >{{ $t("buttons.orderCard") }}</v-btn
        >
      </v-col>
      <v-col lg="4" md="6" cols="12">
        <v-btn
          width="100%"
          class="btn__task"
          :class="{ active: activeButton === 'task' }"
          @click="
            getFilterData(objectProfile, objectVehicle, sortOptions, page),
              buttonTask()
          "
          >{{ $t("buttons.taskTransportation") }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="orderCard">
      <v-col lg="8" md="12">
        <div class="order-col">
          <different-fields
            class="order-field"
            v-for="header in ORDERFIELDS"
            :key="header.value"
            :headerObj="header"
            v-model="ORDER[header.value]"
            :orderEdit="null"
          />
        </div>
        <v-textarea
          :placeholder="labelTextarea"
          :value="ORDER.notation"
          v-model="ORDER.notation"
          background-color="#F1F3F9"
          color="#A6ACC4"
          rows="3"
          no-resize
          filled
        ></v-textarea>
        <v-btn
          color="primary mt-4"
          block
          @click="save()"
          height="38"
          width="450"
          :loading="loadBtn"
        >
          {{ $t("buttons.save") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="task">
      <v-col cols="12">
        <table-edit
          :headers="headers"
          :users_data="TASKSORDER"
          :toEditHeaders="TASKFIELDS"
          :counts="COUNT"
          :toEditItem="TASK"
          :orderEdit="orderId"
          :modalTitle="$t('new.editingTransportationTask')"
          @getItemForEdit="onEditItemFromTable"
          @editRecord="editRecord"
          @deleteRecord="deleteRecord"
          @onPagination="
            getFilterData(
              objectProfile,
              objectVehicle,
              sortOptions,
              ...arguments
            )
          "
          @sortBy="
            getFilterData(objectProfile, objectVehicle, ...arguments, page)
          "
          :page="page"
          :load="load"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="500px">
      <edit-item
        @close="close"
        @edit="createRecord"
        :headers="toEditHeaders"
        :record="newRecord"
        :title="$t('new.addingTransportationTask')"
        :orderEdit="orderId"
        :load="load"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import tableEdit from "@/components/TableEdit";
import differentFields from "@/components/differentFields";
import editItem from "@/components/EditItem.vue";

export default {
  name: "",
  components: { tableEdit, differentFields, editItem },
  data: (vm) => {
    return {
      page: 1,
      objectProfile: undefined,
      objectVehicle: undefined,
      sortOptions: { desc: [false], ordering_field: ["id"] },
      dialog: false,
      newItem: {},
      isValid: false,
      orderCard: true,
      task: false,
      orderId: "",
      activeButton: "order",
      toEditHeaders: [],
      editedItem: null,
      labelTextarea: vm.$t("table.labelTextarea"),
      load: true,
      loadBtn: false,

      headers: [
        { text: vm.$t("table.taskId"), value: "id", visible: true },
        { text: vm.$t("table.created"), value: "creator", visible: true },
        {
          text: vm.$t("table.creationTime"),
          value: "created_dt",
          visible: true,
        },
        { text: vm.$t("table.order"), value: "order", visible: true },
        { text: vm.$t("table.vehicle"), value: "vehicle", visible: true },
        {
          text: vm.$t("table.factEnding"),
          value: "finish_fact_dt",
          visible: true,
        },
        { text: vm.$t("table.status"), value: "finished", visible: true },
        { text: vm.$t("table.weightbox"), value: "weightbox", visible: true },
        { text: "actions", value: "actions", sortable: false, visible: true },
      ],
    };
  },

  props: {
    users_data: {},
    title: {},
  },
  computed: {
    ...mapGetters([
      "ORDER",
      "ORDERFIELDS",
      "TASKFIELDS",
      "TASKSORDER",
      "ADDINGFIELDS",
      "TASK",
      "User",
      "COUNT",
    ]),
    newRecord() {
      return {
        creator: {
          email: this.User.email,
          id: this.User.id,
        },
        order: {
          id: this.ORDER.id,
          name: this.ORDER.name,
        },
      };
    },
  },

  methods: {
    ...mapActions([
      "getOrderByID",
      "getTasksOrder",
      "getFields",
      "taskItem",
      "editBookItem",
      "deleteTask",
      "getTaskByID",
      "createBookItem",
    ]),
    getFilterData(
      profile = this.objectProfile,
      vehicle = this.objectVehicle,
      sort = this.sortOptions,
      page = this.page
    ) {
      console.log(sort);
      this.objectProfile = profile;
      this.objectVehicle = vehicle;
      this.sortOptions = sort;
      this.page = page;

      let desc =
        this.sortOptions.desc.length > 0 && this.sortOptions.desc[0] ? "" : "-";
      let arg = "?";
      this.page ? (arg = arg + `page=${this.page}`) : "?ordering=-id";
      this.objectProfile
        ? (arg = arg + `&driver=${this.objectProfile.id}`)
        : "";
      this.objectVehicle
        ? (arg = arg + `&vehicle=${this.objectVehicle.id}`)
        : "";
      this.sortOptions.ordering_field != ""
        ? (arg =
            arg +
            `${
              this.objectProfile || this.objectVehicle || this.page ? "&" : ""
            }ordering=${desc}${this.sortOptions.ordering_field}`)
        : "";
      this.getTasksOrder({
        id: this.$route.params.userId + `/` + arg,
      });
    },
    getOrder(page) {
      this.page = page;
      this.getTasksOrder({
        id: this.orderId,
        args: `?page=${page}`,
      });
    },
    buttonOrder() {
      this.activeButton = "order";
      this.task = false;
      this.orderCard = true;
    },
    buttonTask() {
      this.activeButton = "task";
      this.orderCard = false;
      this.task = true;
    },
    values(index) {
      if (typeof this.TASK[index] === "object") {
        if (!this.TASK[index].name) return this.ORDER[index].state_number;
        else return this.TASK[index].name;
      }
    },
    onEditItemFromTable(item) {
      this.getTaskByID({ id: item });
    },

    backOrder() {
      this.$router.push({ name: "orderProgress" });
    },
    save() {
      this.loadBtn = true;
      this.editBookItem({
        book: "orderEdit",
        id: `${this.ORDER.id}/`,
        data: this.ORDER,
      }).finally(() => {
        this.getOrderByID({ id: this.orderId });
        this.loadBtn = false;
      });
    },
    close() {
      this.dialog = false;
    },
    onUpdate() {
      this.load = true;
      setTimeout(() => {
        this.page = 1;
        this.getFilterData();
        this.load = false;
      }, 1000);
    },
    createRecord(data) {
      this.dialog = false;
      this.load = true;
      this.createBookItem({ book: "taskCreate", data: data }).finally(() => {
        this.getFilterData();

        this.editedItem = null;
        this.load = false;
      });
    },
    editRecord(data) {
      this.taskItem({
        book: "taskEdit",
        id: `${data.id}/`,
        data: data,
      }).finally(() => {
        this.getFilterData();
      });
    },
    deleteRecord(data) {
      this.deleteTask({ book: "taskDelete", id: `${data}/` }).finally(() => {
        this.getFilterData();
      });
    },
    onAdd() {
      this.getFields({ book: "Task" }).finally(() => {
        this.toEditHeaders = this.ADDINGFIELDS;
        this.dialog = true;
      });
    },
  },
  mounted() {
    this.orderId = this.$route.params.userId;
    this.getOrderByID({ id: this.orderId });
    this.load = false;
  },
};
</script>

<style lang="scss" >
.order-col {
  max-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-wrap: wrap;
  column-gap: 20px;
}
.order-field {
  max-width: 48%;
  width: 100%;
}
@media (max-width: 900px) {
  .order-col {
    max-height: inherit;
  }
  .order-field {
    max-width: 100%;
  }
}
.order-field:nth-child(11) {
  background-color: red !important;
  display: none;
}
</style>