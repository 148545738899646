<template>
  <div>
    <total :total="counts" class="mb-4" />
    <v-data-table
      :headers="visibleHeaders"
      :items="filteredData"
      hide-default-footer
      class="elevation-3 table table__custom"
      :items-per-page="itemsPerPage"
      :options.sync="options"
      :custom-sort="customSort"
      :loading="load"
      sort-by="id"
    >
      <template v-slot:item.finished="{ item }">
        <v-chip :color="getColor(item.finished)" dark>
          {{ item.finished }}
          <p></p>
        </v-chip>
      </template>
      <template v-slot:header.actions>
        <p class="search_category" @click.stop="show = !show">
          <v-icon color="#cbd3e9" small>search</v-icon>
          <span>{{ $t("searchCat") }}</span>
        </p>
      </template>

      <template v-slot:body.prepend v-if="headers">
        <tr v-if="show">
          <td v-for="item in visibleHeaders" :key="`search_${item.value}`">
            <v-text-field
              class="table-search"
              v-if="item.value !== 'actions'"
              height="30"
              append-icon="mdi-magnify"
              hide-details
              background-color="#fff"
              rounded
              single-line
              @input="search($event, item.value)"
            ></v-text-field>
            <v-icon v-else small @click="show = false">close</v-icon>
          </td>
        </tr>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          fab
          x-small
          tag="router-link"
          :to="`/taskEdit/${item.id}`"
          icon
          center
        >
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>

        <v-menu offset-x left nudge-top="50%" nudge-left="10px">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              v-if="verificationRole()"
              @click="deleteItem(item)"
            >
              mdi-close
            </v-icon>
          </template>
          <deliteItem
            @close="dialogDelete = false"
            @confirm="deleteItemConfirm"
          />
        </v-menu>
      </template>

      <template v-slot:no-data>
        <p class="nodata">{{ $t("title.nodata") }}</p>
      </template>
    </v-data-table>

    <div class="pagination-block" v-if="pageCounter > 1">
      <v-pagination
        v-model="_page"
        :length="pageCounter"
        :total-visible="7"
        circle
      />
    </div>

    <v-dialog v-model="dialog" max-width="500px">
      <edit-item
        @close="close"
        @new="createRecord"
        @edit="editRecord"
        :headers="toEditHeaders"
        :record="editedItem"
        :title="modalTitle"
        :orderEdit="orderEdit"
      />
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import editItem from "@/components/EditItem.vue";
import deliteItem from "@/components/DeliteItem.vue";
import total from "@/components/Total.vue";

export default {
  data: () => ({
    itemsPerPage: 10,
    searchFor: {},
    searchValue: "",
    key: "",
    ifSerching: false,
    show: false,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    isEdit: false,
    itemDelete: null,
    options: {},
  }),
  components: { editItem, deliteItem, total },
  props: {
    toEditHeaders: {
      type: Array,
      default: () => [],
    },
    toEditItem: {
      type: Object,
      default: null,
    },
    users_data: {},
    title: {},
    directory: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    orderEdit: {
      type: [String, Number, null],
      default: null,
    },
    page: {
      type: Number,
      default: 1,
    },
    counts: {
      type: Number,
      default: 0,
    },
    load: {
      type: Boolean,
      default: true,
    },
    modalTitle: String,
  },

  computed: {
    _page: {
      get() {
        return this.page;
      },
      set(page) {
        this.$emit("onPagination", page);
      },
    },
    pageCounter() {
      return Math.ceil(this.counts / 10);
    },
    editedItem: {
      get() {
        return this.toEditItem;
      },
      set(newVal) {
        this.$emit("changeEditItem", newVal);
      },
    },
    filteredData() {
      let datas = this.users_data.map(function (item) {
        let newItem = { ...item };
        const keyValues = Object.keys(newItem).map((key) => {
          if (newItem[key] !== null && typeof newItem[key] === "object") {
            newItem[key] = item[key].caption;
          }
          // here the date converts to string
          if (newItem[key] !== null && key === "connected_dt") {
            let date = new Date(item[key] * 1000);
            newItem[key] = date.toString().substring(0, 21);
          }
          if (
            newItem[key] !== null &&
            key === "finished" &&
            newItem[key] === true
          ) {
            newItem[key] = "inActive";
          }
          if (
            newItem[key] !== null &&
            key === "finished" &&
            newItem[key] === false
          ) {
            newItem[key] = "Active";
          }
          return newItem;
        });
        return Object.assign({}, ...keyValues);
      });
      if (!this.ifSerching) {
        return datas;
      } else {
        return datas.filter((item) => {
          if (item[this.key]) {
            return (
              item[this.key]
                .toString()
                .toLowerCase()
                .indexOf(this.searchValue.toLowerCase()) !== -1
            );
          }
        });
      }
    },

    visibleHeaders() {
      return this.headers.filter((header) => header.visible);
    },
    ...mapGetters(["ROLES", "ISUSER", "ISADMINCOMPANY", "ISADMIN", "COUNT"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.sortBy();
      },
      deep: true,
    },
  },

  methods: {
    getColor(finished) {
      if (finished === "Active") {
        return "green";
      }
    },
    customSort(items) {
      return items;
    },
    sortBy() {
      const { sortBy, sortDesc } = this.options;
      this.$emit("sortBy", { ordering_field: sortBy, desc: sortDesc });
    },
    onAdd() {
      this.dialog = true;
      this.$emit("add");
    },
    createRecord(newRecord) {
      this.$emit("createRecord", newRecord);
      this.close();
    },
    editRecord(record) {
      this.$emit("editRecord", record);
      this.close();
    },
    search(val, key) {
      this.searchValue = val;
      this.key = key;
      this.ifSerching = true;
    },
    editItem(item) {
      if (this.verificationRole()) {
        this.$emit("getItemForEdit", item.id);
        this.isEdit = true;
        this.dialog = true;
      }
    },

    deleteItem(item) {
      this.editedIndex = this.users_data.indexOf(item);
      this.itemDelete = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$emit("deleteRecord", this.itemDelete);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.editedItem = null;
      this.isEdit = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.editedItem = null;
    },
    addingRecordsVerification() {
      if (
        this.ISADMIN ||
        (this.ISADMINCOMPANY && !this.directory.match("company"))
      )
        return true;
      else return false;
    },
    verificationRole() {
      if (
        this.ISADMIN ||
        (this.ISADMINCOMPANY && this.ROLES.seller) ||
        (this.ISUSER && this.ROLES.seller) ||
        (this.ISADMINCOMPANY && this.directory.match("company"))
      ) {
        return true;
      } else return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-text-field {
  margin: 0;
  padding: 0;
}
.btn__row {
  display: flex;
  justify-content: flex-end;
  &:last-child {
    margin-top: 10px;
  }
}
.pagination-block {
  margin-top: 40px;
}
.table-search {
  max-width: 200px;
}
.nodata {
  margin: 0;
}
.main-title {
  text-transform: capitalize;
}
</style>
